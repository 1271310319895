<template>
  <Layout>
    <PageHeader :title="title" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />
    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="mapEntitiy"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sorrt-desc.sync="sortDesc"
                :fit-by.sync="sortBy"
                :solter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                hover
              >
                <template v-slot:cell(action)="row">
                  <button
                    class="btn btn-success btn-sm"
                    v-b-tooltip.hover
                    title="Allow Profile Update"
                    target="_blank"
                    @click="getID(row.item)"
                  >
                    <i class="fas fa-cog"></i> Visit Dashboard
                  </button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import store from "@/state/store";
import appConfig from "@/app.config";
import newentitywindowurl from "../../../helpers/newentitywindowurl";
export default {
  page: {
    title: "My Entities",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Alert,
    PageHeader,
    Layout,
  },
  data() {
    return {
      // data table meta
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: "Name" },
        { key: "afcfta_number", sortable: true, label: "AfCFTA Number" },
        { key: "type", sortable: true, label: "Type" },
        { key: "Action" },
      ],
      //end of table meta
      loading: false,
      id: null,
      requestError: null,
      success: null,
      error: null,
      key: null,
      title: "My Entities",
      items: [
        {
          text: "AfCFTA Caravan",
        },
        {
          text: "My Entities",
          active: true,
        },
      ],
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    newentitywindowurl() {
      return newentitywindowurl();
    },
    getID(entity) {
      store.dispatch("auth/getCurrentEntity", entity).then(() => {
        const { href } = this.$router.resolve({
          path: `/entity/${entity.id}/dashboard`,
        });
        window.open(href, "_blank");
      });
    },
  },
  computed: {
    entities() {
      return store.getters["auth/entities"];
    },
    rows() {
      return this.mapEntitiy && this.mapEntitiy.length;
    },
    mapEntitiy() {
      return this.entities ? this.entities.map((entity) => entity.entity) : [];
    },
  },
  created() {},
  mounted() {
    this.totalRows = this.mapEntitiy.length;
  },
};
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>
